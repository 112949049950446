<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="items"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @selection-changed="onSelectionChanged"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>
    </b-overlay>

    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                items.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : items.length
              }}
              de {{ items.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import {
  BCard,
  BCardText,
  BOverlay,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    BOverlay,
    BPagination,
    BRow,
    BCol,
  },
  props: ["items", "loading"],
  data() {
    return {
      // grid
      gridOptions: {
        suppressPropertyNamesCheck: true,
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        //editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 160,
          maxWidth: 160,
        },
        {
          headerName: "TIPO DOCUMENTO",
          field: "tipo_documento.descripcion",
          filter: false,
          minWidth: 270,
          maxWidth: 270,
        },
        {
          headerName: "NUMERO DOCUMENTO",
          field: "numero_documento",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        // {
        //     headerName: 'NRO DOC. COMPL.',
        //     field: 'nro_documento_complemento',
        //     filter: false,
        //     minWidth: 200, maxWidth: 200,
        // },
        {
          headerName: "COMPLEMENTO",
          field: "complemento",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: "RAZON SOCIAL",
          field: "razon_social",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "EMAIL",
          field: "correo_electronico",
          filter: false,
          minWidth: 300,
          maxWidth: 300,
        },
        {
          headerName: "CELULAR",
          field: "celular",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: "VERIFICADO (NIT)",
          filter: false,
          cellRenderer: function (param) {
            if (param.data.es_verificado == 0) {
              return "NO";
            } else {
              return "SI";
            }
          },
          minWidth: 160,
          maxWidth: 160,
        },
        // {
        //     headerName: 'ESTADO',
        //     filter: false,
        //     cellRenderer: function(param){
        //         if(param.data.estado==0){
        //             return 'INACTIVO';
        //         }else{
        //             return 'ACTIVO';
        //         }
        //     },
        //     minWidth: 120, maxWidth: 120,
        // },
      ],
      rowClassRules: { "row-red": "data.estado === 0" },
      searchQuery: "",
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created() {},
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemCliente", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemCliente", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
</style>
