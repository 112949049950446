<template>
  <ClienteProveedorLayout
    titulo="Administración - Clientes"
    tipo="cliente"
    :service="clienteService"
  />
</template>

<script>
import ClienteProveedorLayout from "./ClienteProveedorLayout.vue";
import { ClienteService } from "../services/index";

export default {
  name: "ClienteLayout",
  components: {
    ClienteProveedorLayout,
  },
  data() {
    return {
      clienteService: ClienteService,
    };
  },
};
</script>

<style>
</style>
