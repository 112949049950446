var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":_vm.titulo}},[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"demo-inline-spacing"},[(
                (_vm.$hascan('cliente-registrar') &&
                  _vm.$route.name == 'administracion-clientes') ||
                (_vm.$hascan('proveedor-registrar') &&
                  _vm.$route.name == 'administracion-proveedores')
              )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.modalFormRef.abrir('registrar', [])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("NUEVO")])],1):_vm._e(),(
                (_vm.$hascan('cliente-editar') &&
                  _vm.$route.name == 'administracion-clientes') ||
                (_vm.$hascan('proveedor-editar') &&
                  _vm.$route.name == 'administracion-proveedores')
              )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"dark","size":"sm","disabled":_vm.buttonDisabled},on:{"click":function($event){return _vm.$refs.modalFormRef.abrir('actualizar', _vm.item)}}},[_c('span',{staticClass:"d-none d-md-block"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("EDITAR")])],1),_c('feather-icon',{staticClass:"d-md-none",attrs:{"icon":"Edit2Icon","size":"14"}})],1):_vm._e(),(
                (_vm.$hascan('cliente-eliminar') &&
                  _vm.$route.name == 'administracion-clientes') ||
                (_vm.$hascan('proveedor-eliminar') &&
                  _vm.$route.name == 'administracion-proveedores')
              )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"dark","size":"sm","disabled":_vm.buttonDisabled},on:{"click":function($event){return _vm.confirmarEliminar(_vm.item)}}},[_c('span',{staticClass:"d-none d-md-block"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("ELIMINAR")])],1),_c('feather-icon',{staticClass:"d-md-none",attrs:{"icon":"XIcon","size":"14"}})],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"dark","size":"sm"},on:{"click":_vm.listar}},[_c('span',{staticClass:"d-none d-md-block"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"RefreshCcwIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("RECARGAR")])],1),_c('feather-icon',{staticClass:"d-md-none",attrs:{"icon":"RefreshCcwIcon","size":"14"}})],1)],1)]),_c('b-col',{staticClass:"mt-1 mt-md-0",attrs:{"cols":"12","md":"4"}},[_c('b-row',{staticStyle:{"justify-content":"flex-end"}},[_c('b-col',{attrs:{"cols":"12","md":"10","xl":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"","label-align-sm":"right","label-for":"filterInput"}},[_c('b-form-input',{attrs:{"formatter":_vm.textFormatter,"type":"search","size":"sm","placeholder":"Buscar.."},on:{"input":_vm.updateSearchQuery},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1)],1)],1)],1)],1),_c('b-card-text',[_c('Listado',{ref:"listadoRef",attrs:{"items":_vm.items,"loading":_vm.loading},on:{"selectItemCliente":_vm.selectItemCliente}})],1)],1),_c('ModalFormClienteProveedor',{ref:"modalFormRef",attrs:{"tipo":_vm.tipo,"item":_vm.item,"service":_vm.service},on:{"actualizarCliente":_vm.actualizarCliente}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }