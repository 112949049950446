var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-from-cliente","title":_vm.tituloModal,"no-close-on-backdrop":"","ok-disabled":_vm.submitted,"cancel-disabled":_vm.submitted,"ok-title":_vm.verificandonit ? 'Continuar de todos modos' : 'Registrar',"ok-variant":"dark","cancel-variant":"outline-dark","cancel-title":"Cerrar","button-size":"sm","size":"lg"},on:{"cancel":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"close":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}},model:{value:(_vm.modalCliente),callback:function ($$v) {_vm.modalCliente=$$v},expression:"modalCliente"}},[_c('b-overlay',{attrs:{"show":_vm.submitted,"no-wrap":"","spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}}),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":"Tipo de documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"* DOCUMENTO","label-for":"tipoDocumento","label-cols":"12","label-cols-lg":"3","state":errors.length > 0 ? false : null}},[_c('b-form-select',{attrs:{"id":"tipoDocumento","disabled":_vm.form.numero_documento == '99001',"value-field":"id","text-field":"descripcion","options":_vm.tipoDocumentoItems,"size":"sm","state":errors.length > 0 ? false : null},model:{value:(_vm.form.tipo_documento_id),callback:function ($$v) {_vm.$set(_vm.form, "tipo_documento_id", _vm._n($$v))},expression:"form.tipo_documento_id"}}),_vm._l((_vm.errores.tipo_documento_id),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})],2)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Numero de documento","rules":_vm.numeroDocumento},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"numeroDocumento","disabled":_vm.form.numero_documento == '99001',"size":"sm","state":errors.length > 0 ? false : null},model:{value:(_vm.form.numero_documento),callback:function ($$v) {_vm.$set(_vm.form, "numero_documento", $$v)},expression:"form.numero_documento"}}),_vm._l((_vm.errores.numero_documento),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])}),_vm._l((_vm.errores.nro_documento_complemento),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"1"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Complemento","rules":"max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.form.tipo_documento_id != 1,"id":"complemento","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.complemento),callback:function ($$v) {_vm.$set(_vm.form, "complemento", $$v)},expression:"form.complemento"}}),_vm._l((_vm.errores.complemento),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"* RAZON SOCIAL","label-for":"razon_social","label-cols":"12","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Razon social","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"razon_social","formatter":_vm.textFormatter,"lazy-formatter":"","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.razon_social),callback:function ($$v) {_vm.$set(_vm.form, "razon_social", $$v)},expression:"form.razon_social"}}),_vm._l((_vm.errores.razon_social),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"DIRECCION","label-for":"direccion","label-cols":"12","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Direccion","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"direccion","disabled":_vm.form.numero_documento == '99001',"formatter":_vm.textFormatter,"lazy-formatter":"","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.direccion),callback:function ($$v) {_vm.$set(_vm.form, "direccion", $$v)},expression:"form.direccion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.direccion),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"CORREO","label-for":"correo_electronico","label-cols":"12","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Correo electronico","rules":"email|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"correo_electronico","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.correo_electronico),callback:function ($$v) {_vm.$set(_vm.form, "correo_electronico", $$v)},expression:"form.correo_electronico"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.correo_electronico),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"CELULAR","label-for":"celular","label-cols":"12","label-cols-lg":"4","label-align-xl":"right"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"integer|digits:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" BO (+591) ")]),_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"id":"celular","disabled":_vm.form.numero_documento == '99001',"raw":false,"state":errors.length > 0 ? false : null,"options":_vm.options.phone,"placeholder":"75757575"},model:{value:(_vm.form.celular),callback:function ($$v) {_vm.$set(_vm.form, "celular", $$v)},expression:"form.celular"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.celular),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"CIUDAD","label-for":"ciudad","label-cols":"12","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Ciudad","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ciudad","disabled":_vm.form.numero_documento == '99001',"formatter":_vm.textFormatter,"lazy-formatter":"","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.ciudad),callback:function ($$v) {_vm.$set(_vm.form, "ciudad", $$v)},expression:"form.ciudad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.ciudad),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"ZONA","label-for":"zona","label-cols":"12","label-cols-lg":"4","label-align-xl":"right"}},[_c('validation-provider',{attrs:{"name":"Zona","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"zona","disabled":_vm.form.numero_documento == '99001',"formatter":_vm.textFormatter,"lazy-formatter":"","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.zona),callback:function ($$v) {_vm.$set(_vm.form, "zona", $$v)},expression:"form.zona"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.zona),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }