<template>
  <div>
    <b-card :title="titulo">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="8">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                v-if="
                  ($hascan('cliente-registrar') &&
                    $route.name == 'administracion-clientes') ||
                  ($hascan('proveedor-registrar') &&
                    $route.name == 'administracion-proveedores')
                "
                @click="$refs.modalFormRef.abrir('registrar', [])"
              >
                <feather-icon icon="SaveIcon" class="mr-50" />
                <span class="align-middle">NUEVO</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="
                  ($hascan('cliente-editar') &&
                    $route.name == 'administracion-clientes') ||
                  ($hascan('proveedor-editar') &&
                    $route.name == 'administracion-proveedores')
                "
                @click="$refs.modalFormRef.abrir('actualizar', item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span class="align-middle">EDITAR</span>
                </span>
                <feather-icon icon="Edit2Icon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="
                  ($hascan('cliente-eliminar') &&
                    $route.name == 'administracion-clientes') ||
                  ($hascan('proveedor-eliminar') &&
                    $route.name == 'administracion-proveedores')
                "
                @click="confirmarEliminar(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">ELIMINAR</span>
                </span>
                <feather-icon icon="XIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" md="4" class="mt-1 mt-md-0">
            <b-row style="justify-content: flex-end">
              <b-col cols="12" md="10" xl="6">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>

      <b-card-text>
        <Listado
          ref="listadoRef"
          :items="items"
          :loading="loading"
          @selectItemCliente="selectItemCliente"
        />
      </b-card-text>
    </b-card>

    <ModalFormClienteProveedor
      ref="modalFormRef"
      :tipo="tipo"
      :item="item"
      @actualizarCliente="actualizarCliente"
      :service="service"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import ModalFormClienteProveedor from "../components/ModalFormClienteProveedor.vue";

export default {
  name: "ClienteProveedorLayout",
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    Listado,
    BRow,
    BCol,
    ModalFormClienteProveedor,
  },
  props: {
    titulo: {
      type: String,
      required: true,
    },
    tipo: {
      type: String,
      required: true,
      validator: function (value) {
        return ["cliente", "proveedor"].indexOf(value) !== -1;
      },
    },
    service: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      items: [],
      item: [],
      buttonDisabled: true,
      searchQuery: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,
    };
  },
  created() {},
  methods: {
    // SERVICES
    async listar() {
      this.loading = true;
      try {
        const response = await this.service.get();
        this.items = response.data;
        this.item = [];
        this.buttonDisabled = true;
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async eliminar(id) {
      try {
        await this.service.destroy(id);
        this.$bvToast.toast("Se elimino correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });
        this.listar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // Emit modal cliente
    actualizarCliente() {
      this.listar();
      this.item = [];
      this.buttonDisabled = true;
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.listar();
    },
    selectItemCliente(item, bool) {
      this.item = item;
      if (
        this.item.numero_documento == "99003" ||
        this.item.numero_documento == "99002"
      ) {
        this.buttonDisabled = true;
      } else {
        this.buttonDisabled = bool;
      }
    },
    updateSearchQuery(val) {
      this.$refs.listadoRef.updateSearchQuery(val);
    },
    confirmarEliminar(item) {
      this.$bvModal
        .msgBoxConfirm("Confirme que desea eliminar : " + item.razon_social, {
          title: "ELIMINAR CLIENTE ?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si, Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-dark",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.eliminar(item.id);
          }
        });
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
  },
  mounted() {
    this.listar();
  },
};
</script>

<style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
