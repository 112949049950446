<template>
  <b-modal
    id="modal-from-cliente"
    v-model="modalCliente"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    :ok-title="verificandonit ? 'Continuar de todos modos' : 'Registrar'"
    ok-variant="dark"
    cancel-variant="outline-dark"
    cancel-title="Cerrar"
    button-size="sm"
    size="lg"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <!-- no-close-on-esc -->
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12" md="8">
            <validation-provider
              #default="{ errors }"
              name="Tipo de documento"
              rules="required"
            >
              <b-form-group
                label="* DOCUMENTO"
                label-for="tipoDocumento"
                label-cols="12"
                label-cols-lg="3"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select
                  id="tipoDocumento"
                  :disabled="form.numero_documento == '99001'"
                  v-model.number="form.tipo_documento_id"
                  value-field="id"
                  text-field="descripcion"
                  :options="tipoDocumentoItems"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                <small
                  v-for="error in errores.tipo_documento_id"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Numero de documento"
                :rules="numeroDocumento"
              >
                <b-form-input
                  id="numeroDocumento"
                  :disabled="form.numero_documento == '99001'"
                  v-model="form.numero_documento"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                <small
                  v-for="error in errores.numero_documento"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
                <small
                  v-for="error in errores.nro_documento_complemento"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="1">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Complemento"
                rules="max:5"
              >
                <b-form-input
                  :disabled="form.tipo_documento_id != 1"
                  id="complemento"
                  v-model="form.complemento"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                <small
                  v-for="error in errores.complemento"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12" md="12">
            <b-form-group
              label="* RAZON SOCIAL"
              label-for="razon_social"
              label-cols="12"
              label-cols-lg="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Razon social"
                rules="required|max:200"
              >
                <b-form-input
                  id="razon_social"
                  :formatter="textFormatter"
                  lazy-formatter
                  v-model="form.razon_social"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                <small
                  v-for="error in errores.razon_social"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12" md="12">
            <b-form-group
              label="DIRECCION"
              label-for="direccion"
              label-cols="12"
              label-cols-lg="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Direccion"
                rules="max:200"
              >
                <b-form-input
                  id="direccion"
                  :disabled="form.numero_documento == '99001'"
                  :formatter="textFormatter"
                  lazy-formatter
                  v-model="form.direccion"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.direccion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12" md="6">
            <b-form-group
              label="CORREO"
              label-for="correo_electronico"
              label-cols="12"
              label-cols-lg="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Correo electronico"
                rules="email|max:100"
              >
                <b-form-input
                  id="correo_electronico"
                  v-model="form.correo_electronico"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.correo_electronico"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              label="CELULAR"
              label-for="celular"
              label-cols="12"
              label-cols-lg="4"
              label-align-xl="right"
            >
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="integer|digits:8"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    BO (+591)
                  </b-input-group-prepend>
                  <cleave
                    id="celular"
                    v-model="form.celular"
                    :disabled="form.numero_documento == '99001'"
                    class="form-control form-control-sm"
                    :raw="false"
                    :state="errors.length > 0 ? false : null"
                    :options="options.phone"
                    placeholder="75757575"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.celular"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12" md="6">
            <b-form-group
              label="CIUDAD"
              label-for="ciudad"
              label-cols="12"
              label-cols-lg="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Ciudad"
                rules="max:50"
              >
                <b-form-input
                  id="ciudad"
                  :disabled="form.numero_documento == '99001'"
                  :formatter="textFormatter"
                  lazy-formatter
                  v-model="form.ciudad"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.ciudad"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              label="ZONA"
              label-for="zona"
              label-cols="12"
              label-cols-lg="4"
              label-align-xl="right"
            >
              <validation-provider
                #default="{ errors }"
                name="Zona"
                rules="max:50"
              >
                <b-form-input
                  id="zona"
                  :disabled="form.numero_documento == '99001'"
                  :formatter="textFormatter"
                  lazy-formatter
                  v-model="form.zona"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.zona"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TipoDocumentoService from "@/modules/configuracion/tipo-documento/services/index";
// import FacturacionServices from "@/modules/configuracion-sin/services/index";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BInputGroup,
  BFormFile,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.bo";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  name: "ModalFormClienteProveedor",
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormSelect,
    BInputGroup,
    BFormFile,
    BOverlay,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    Cleave,
    BInputGroupPrepend,
  },
  props: {
    item: {
      type: [Array, Object],
      required: true,
    },
    tipo: {
      type: String,
      required: true,
      validator: (value) => {
        return ["cliente", "proveedor"].includes(value);
      },
    },
    service: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        // codigo:'',
        tipo_documento_id: null,
        numero_documento: "",
        complemento: "",
        razon_social: "",
        direccion: "",
        ciudad: "",
        zona: "",
        correo_electronico: "",
        celular: "",
        es_verificado: 0,
        nro_documento_complemento: "",
      },
      required,
      submitted: false,
      errores: [],
      tipoDocumentoItems: [],
      unidadItems: [],
      catalogoItems: [],
      modalCliente: false,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: "BO",
        },
      },
      verificandonit: false,
    };
  },
  computed: {
    tituloModal() {
      return this.form.id
        ? `EDITAR ${this.tipo.toUpperCase()}`
        : `NUEVO ${this.tipo.toUpperCase()}`;
    },
    numeroDocumento() {
      if (
        this.form.tipo_documento_id == 1 ||
        this.form.tipo_documento_id == 5
      ) {
        return "required|integer|max:20";
      } else {
        return "required|max:20";
      }
    },
  },
  watch: {
    "form.razon_social": function () {
      this.errores.razon_social = [];
    },
    // 'form.codigo': function () {
    //     this.errores.codigo = [];
    // },
    "form.correo_electronico": function () {
      this.errores.correo_electronico = [];
    },
    "form.tipo_documento_id": function () {
      if (this.form.tipo_documento_id != 1) {
        this.form.complemento = "";
      }
      this.errores.tipo_documento_id = [];
    },
    "form.complemento": function () {
      this.errores.complemento = [];
    },
    "form.numero_documento": function () {
      this.errores.numero_documento = [];
    },
    "form.direccion": function () {
      this.errores.direccion = [];
    },
    "form.ciudad": function () {
      this.errores.ciudad = [];
    },
    "form.zona": function () {
      this.errores.zona = [];
    },
    "form.celular": function () {
      this.errores.celular = [];
    },
    "form.nro_documento_complemento": function () {
      this.errores.nro_documento_complemento = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.form.complemento = this.form.complemento
            ? this.form.complemento
            : "";
          this.form.nro_documento_complemento =
            this.form.numero_documento + this.form.complemento;
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
            // Esto en vez de lo de arriba
            // if (this.form.tipo_documento_id == 5 && !this.verificandonit) {
            //   this.verificarNit();
            // } else {
            //   if (this.form.id) {
            //     this.actualizar();
            //   } else {
            //     this.registrar();
            //   }
            // }
          }
        }
      });
    },
    abrir(accion, data = []) {
      this.verificandonit = false;
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.tipo_documento_id = null;
          // this.form.codigo='';
          this.form.numero_documento = "";
          this.form.complemento = "";
          this.form.razon_social = "";
          this.form.direccion = "";
          this.form.ciudad = "";
          this.form.zona = "";
          this.form.correo_electronico = "";
          this.form.celular = "";
          this.form.es_verificado = 0;
          this.modalCliente = true;
          this.form.nro_documento_complemento = "";
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.tipo_documento_id = data["tipo_documento_id"];
          // this.form.codigo=data['codigo'];
          this.form.numero_documento = data["numero_documento"];
          this.form.complemento = data["complemento"]
            ? data["complemento"]
            : "";
          this.form.razon_social = data["razon_social"];
          this.form.direccion = data["direccion"];
          this.form.ciudad = data["ciudad"];
          this.form.zona = data["zona"];
          this.form.correo_electronico = data["correo_electronico"];
          this.form.celular = data["celular"];
          this.form.es_verificado = data["es_verificado"];
          this.form.nro_documento_complemento =
            data["nro_documento_complemento"];
          this.modalCliente = true;
          break;
        }
        case "registrarCliente": {
          this.form.id = null;
          this.form.tipo_documento_id = null;
          this.form.numero_documento = data;
          this.form.complemento = "";
          this.form.razon_social = "";
          this.form.direccion = "";
          this.form.ciudad = "";
          this.form.zona = "";
          this.form.correo_electronico = "";
          this.form.celular = "";
          this.form.es_verificado = 0;
          this.modalCliente = true;
          this.form.nro_documento_complemento = "";
          break;
        }
      }
      this.errores = [];
      setTimeout(() => {
        this.$nextTick(() => {
          document.getElementById("tipoDocumento").focus();
          this.$refs.simpleRules.reset();
        });
      }, 200);
    },
    cerrarModal() {
      this.form.id = null;
      this.modalCliente = false;
    },
    actualizarCliente(item) {
      this.form.id = null;
      this.modalCliente = false;
      this.$emit("actualizarCliente", item);
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      try {
        const response = await this.service.store(this.form);
        this.actualizarCliente(response.data.cliente_proveedor);
        this.errores = [];
        this.$bvToast.toast("Registrado correctamente", {
          title: "EXITO!",
          variant: "success",
          solid: false,
        });
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await this.service.update(this.form.id, this.form);
        this.actualizarCliente(response.data.cliente_proveedor);
        this.errores = [];
        this.$bvToast.toast("Actualizado correctamente", {
          title: "EXITO!",
          variant: "success",
          solid: false,
        });
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // async verificarNit() {
    //   this.submitted = true;
    //   if (!this.form.es_verificado) {
    //     FacturacionServices.verficarNit(this.form.numero_documento)
    //       .then((res) => {
    //         if (res.data.transaccion) {
    //           this.form.es_verificado = 1;

    //           this.submitted = true;
    //           if (this.form.id) {
    //             this.actualizar();
    //           } else {
    //             this.registrar();
    //           }
    //         } else {
    //           this.submitted = false;
    //           this.$bvToast.toast("No se pudo verificar el NIT", {
    //             title: "ADVERTENCIA!",
    //             variant: "danger",
    //             solid: false,
    //           });
    //           this.form.es_verificado = 0;
    //           this.verificandonit = true;
    //         }
    //       })
    //       .catch((err) => {
    //         this.verificandonit = true;
    //         window.console.log(err);
    //         this.submitted = false;
    //       });
    //   } else {
    //     this.submitted = true;
    //     if (this.form.id) {
    //       this.actualizar();
    //     } else {
    //       this.registrar();
    //     }
    //   }
    // },
    // documentos de identidad
    async getDocumentoIdentidad() {
      try {
        const response = await TipoDocumentoService.get({ estado: 1 });
        this.tipoDocumentoItems = response.data;
      } catch (err) {
        console.log(err);

        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
  },
  mounted() {
    this.getDocumentoIdentidad();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>